<template>
    <v-row>
        <v-col cols="10" offset="1">
            <v-card>
                <v-toolbar flat color="transparent">
                    <v-text-field
                        flat
                        hide-details
                        single-line
                        :label="$t('projects.search')"
                        prepend-inner-icon="mdi-magnify"
                        v-model="search"
                        full-width
                    ></v-text-field>
                    <v-spacer v-if="profile.security.administration"></v-spacer>
                    <v-dialog
                        v-if="profile.security.administration"
                        v-model="dialogCreateProject"
                        max-width="500px"
                        persistent
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn rounded color="primary" dark v-on="on">
                                {{ $t('buttons.createProject') }}
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="headline blue--text text--darken-1">{{ $t('title.newProject') }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-text-field
                                    v-model="nameCreate"
                                    :counter="50"
                                    :label="$t('projects.attributes.label')"
                                    required
                                    color="red"
                                ></v-text-field>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="red darken-1" text @click="cancelCreate">
                                    {{ $t('buttons.cancel') }}
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="create">
                                    {{ $t('buttons.save') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
                <v-list dense rounded>
                    <v-subheader>{{ $t('projects.favorites') }}</v-subheader>
                    <v-list-item
                        v-for="project in favorites"
                        :key="project.id"
                        @click="openProject(project)"
                        class="title"
                    >
                        <v-list-item-icon>
                            <v-icon :color="project.color" v-text="'mdi-' + project.icon" large />
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="project.label" />
                            <v-list-item-subtitle v-text="project.description" />
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider v-if="favorites.length > 0"></v-divider>

                    <template v-for="(group, j) in groups">
                        <v-subheader :key="j">{{ group }}</v-subheader>
                        <v-list-item
                            v-for="project in findProjectByGroup(group)"
                            :key="project.id"
                            @click="openProject(project)"
                            class="subtitle-1"
                        >
                            <v-list-item-icon>
                                <v-icon :color="project.color" v-text="'mdi-' + project.icon" large />
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="project.label" />
                                <v-list-item-subtitle v-text="project.description" />
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OrderBy from 'lodash/orderBy';

export default {
    name: 'projects',
    data: () => ({
        dialogCreateProject: false,
        nameCreate: undefined,
        search: undefined,
    }),
    created() {
        this.fetchProjects();
    },
    computed: {
        ...mapGetters(['projects', 'profile']),
        favorites() {
            return OrderBy(
                this.projects.filter((p) => p.favorites.includes(this.profile.name)),
                ['group', 'label'],
                ['asc', 'asc'],
            );
        },
        notFavorites() {
            const filterProjects = this.projects.filter((p) => {
                let allow = !p.favorites.includes(this.profile.name);
                if (this.search) {
                    allow = allow && p.label.toLowerCase().includes(this.search.toLowerCase());
                }
                return allow;
            });

            return OrderBy(filterProjects, ['group', 'label'], ['asc', 'asc']);
        },
        favoritesId() {
            return this.favorites.map((p) => p.id);
        },
        groups() {
            return new Set(this.notFavorites.map((p) => p.group));
        },
    },
    methods: {
        ...mapActions(['findProjects', 'createProject']),
        cancelCreate() {
            this.dialogCreateProject = false;
            this.nameCreate = undefined;
        },
        create() {
            const projectToCreate = {
                label: this.nameCreate,
            };

            this.createProject(projectToCreate);
            this.cancelCreate();
        },
        fetchProjects() {
            this.findProjects();
        },
        findProjectByGroup(group) {
            return this.notFavorites.filter((p) => p.group === group);
        },
        openProject(project) {
            this.$router.push({ name: 'project', params: { project_id: project.id } });
        },
    },
};
</script>

<style></style>
